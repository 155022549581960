import styled, { css } from "styled-components"
import breakpoints from "./breakpoints"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"
import { semibold16, regular12, bold16, bold14 } from "./typography"
import { strikethrough } from "../styledComponents/base";

export const closeModalButtonStyles = css`
  position: sticky;
  top: 1.4rem;
  left: calc(100% - 2.4rem);
  min-width: auto;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  margin-top: 0;
  margin-right: 0;

  @media screen and (min-width: ${breakpoints.md}px) {
    left: calc(100% - 3.2rem);
  }
  &.close-audio {
    background: transparent;
    transform: rotate(180deg);
    svg {
      position: absolute;
      left: 0.4rem;
      top: 0.3rem;
      width: 24px;
      height: 30px;
      path {
        fill: var(--green-strong);
      }
    }
  }
  svg {
    position: absolute;
    left: 1.1rem;
    top: 1.3rem;
    pointer-events: none;
  }

  .topBun {
    fill: #fff;
    transform: rotate(45deg);
    transition: all 0.35s ease-in-out;
    transform-origin: 0.1rem 0.4rem;
  }

  .bottomBun {
    fill: #fff;
    transform: rotate(-45deg);
    transition: all 0.35s ease-in-out;
    transform-origin: 0 1rem;
  }
`
export const ButtonStyles = css`
  background: var(--light-purple-darker);
  border: 0;
  border-radius: 4px;
  padding:${props => props.inverse ? '1.4rem 1.6rem;' : '1.6rem;'}
  color: var(--grey-lightest);
  ${semibold16};
  font-weight: 700;
  line-height: 1.6rem;
  min-width: 16rem;
  width: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.noMargin ? '1.6rem 0' : '1.6rem auto 0'};

  &:hover,
  &:focus {
    background: var(--light-purple-darkest);
    cursor: pointer;
    ${props => props.inverse && 'background: (--light-purple-mid);'}
    ${props => props.inverse && 'color: var(--white);'}
    ${props => props.inverse && 'border:1px solid var(--light-purple-dark);'}
  }
  &:disabled, 
  &.disabled {
    opacity: 0.2;
    cursor: auto;
    background: var(--light-purple-darkest);
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 1.6rem auto 0 ${props => (props.align ? "0" : "auto")};
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin: 2.4rem auto 0 ${props => (props.align ? "0" : "auto")};
  }
  `
export const AnchorLinkStyles = css`
  ${ButtonStyles}
  color: ${props => props.inverse ? 'var(--white)' : 'var(--white)'};
  background-color: ${props => props.inverse ? 'var(--light-purple-darkest)' : 'var(--light-green-dark)'};
  line-height: 1.4;
  @media screen and (min-width: ${breakpoints.l}px) {
    width: 80%;
    margin-left: 0;
  }
  &:visited,
  &:hover{
    color: var(--white);
    border-bottom: 0;
  }
  &:hover{
    background: var(--light-purple-darker);
  }
  &.special-offer {
    text-align: center;
    @media screen and (min-width: ${breakpoints.l}px) {
      margin-top: 1.6rem;
    }
  }
`

const StyledButton = styled.button`
  ${ButtonStyles}
  ${props => props.isInView && 'width:100%;display:block;'}
  ${props => props.inverse && 'background: #fff;'}
  ${props => props.inverse && 'color: var(--light-purple-dark);'}
  ${props => props.inverse && 'border:1px solid var(--light-purple-dark);'}
  &.learn-more {
    background: var(--light-purple-darkest);
    border:1px solid var(--white);
    border-radius: 10px;
    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 25rem;
    }
  }

  &.listen-now {
      background-color: var(--green-strong);
      @media screen and (min-width: ${breakpoints.md}px) {
        width: 30%;
        margin-left: 0;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        width: 30%;
      }
    }
  abbr[title]{
    text-decoration: none; /* 2 */
    text-decoration: none dotted; /* 2 */
    margin-left: 0.8rem;
  }
`

const StyledButtonAsLink = styled.button`
  border: 0;
  padding: 0;
  background: none;
  color: var(--light-purple-darker);
  text-decoration: none;
  word-break: break-word;
  border-bottom: 1px dotted var(--light-green-dark);
  &:hover {
    cursor: pointer;
  }
`

const StyledButtonLink = styled.a`
  ${props => props.isInView && 'width:100%;display:block;'}
  ${regular12}
  border-bottom: 0;
  color: var(--white);

  footer & {
    &:hover,
    &:active,
    &:visited {
      color: var(--white);
    }
  }
`

const StyledLinkButton = styled(Link)`
  background: ${props => props.inverse ? 'var(--white)' : 'var(--light-green-darkest)'};
  border: 0;
  border-radius: 4px;
  padding: 1.6rem;
  color: ${props => props.inverse ? 'var(--light-green-darkest)' : 'var(--grey-lightest)'};
  display: inline-block;
  min-width: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${semibold16};
  font-weight:
  line-height: 1.6rem;
  margin: ${props => props.noMargin ? '0' : '1.6rem auto 0'};
  text-decoration: none;
  white-space: nowrap;
  &:visited {
    color: ${props => props.inverse ? 'var(--light-green-darkest)' : 'var(--grey-lightest)'};
  }
  &:hover {
    border-bottom: 0;
    cursor: pointer;
    color: var(--grey-lightest);
  }
  &:disabled {
    background: var(--green-light);
    cursor: auto;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    width: 15.8rem;
    margin: ${props => props.noMargin ? '0' : '1.6rem auto 0'};
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: ${props => props.noMargin ? '0' : '2.4rem auto 0'};
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    width: 16.5rem;
  }

  &.click-to-shop {
    margin-left: 0;
  }
  
  &.read-more {
    background: var(--light-purple-darker);
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 700;

    &.event {
      margin-bottom:1.6rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      width: 30%;
      margin-left: 0;
      margin-top: 1.6rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      width: 30%;
    }
    &:hover {
      background: var(--light-purple-darkest);
    }
  }
  &.external {
    margin-left: 0;
  }
  svg {
    width: 2rem;
  }
`

const StyledCloseModalButton = styled(StyledButton)`

  .index-modal &,
  .order-modal &, 
  .deposit-modal &,
  .single-audio-modal &,
  .notification &,
  .audio-list-modal &,
  .shm-program-modal &,
  .permission-reset-sleep-modal &,
  .spoon-therapy-modal &,
  .sign-in-modal & {
    position: sticky;
    top: 1.4rem;
    left: calc(100% - 2.4rem);
    min-width: auto;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: block;
    margin-top: 0;
    margin-right: 0;

    @media screen and (min-width: ${breakpoints.md}px) {
      left: calc(100% - 3.2rem);
    }
    &.close-audio {
      background: transparent;
      transform: rotate(180deg);
      svg {
        position: absolute;
        left: 0.4rem;
        top: 0.3rem;
        width: 24px;
        height: 30px;
        path {
          fill: var(--green-strong);
        }
      }
    }
    svg {
      position: absolute;
      left: 1.1rem;
      top: 1.3rem;
      pointer-events: none;
    }

    .topBun {
      fill: #fff;
      transform: rotate(45deg);
      transition: all 0.35s ease-in-out;
      transform-origin: 0.1rem 0.4rem;
    }

    .bottomBun {
      fill: #fff;
      transform: rotate(-45deg);
      transition: all 0.35s ease-in-out;
      transform-origin: 0 1rem;
    }
  }

  .notification & {
    position: absolute;
    top: 0.8rem;
    right: 0rem;
    left: calc(100% - 6rem);
    z-index: 10;
    background-color: transparent;
    svg .topBun,
    svg .bottomBun{
      fill: #fff;
      z-index: 1;
    }
  }
  .audio-list-modal & {
    margin-right: 0;
    /* top: 1.6rem; */
  } 
  .more-info-modal & {
    background-color: transparent;
    top: 1.2rem;
    right: 0.8rem;
    position: absolute;
    left: auto;
    z-index: 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
      top: 1.6rem;
      right: 1.6rem;
    }
    svg .topBun,
    svg .bottomBun{
      fill: #fff;
      z-index: 1;
    }
  }
`

const StyledAddToCartButton = styled(StyledButton)`
  margin-top: 0;  
`
const StyledCheckoutButton = styled(StyledButton)`
  position: absolute;
  bottom: 1.6rem;
  right: 0;
  min-width:13rem;

  &:disabled {
    opacity: 1;
    cursor: auto;
    background: var(--light-purple-mid);
    color: var(--light-purple-darkest);
    
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    background: var(--grey-lightest);
    color: var(--light-purple-darkest);
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    right: 3.2rem;
    bottom: 1.6rem;
    background: var(--grey-lightest);
    color: var(--light-purple-darkest);

    &:hover,
    &:focus {
      background: var(--grey-lightest);
      cursor: pointer;
    }
  }
`

const StyledClearCartButton = styled(StyledButton)`
  position: absolute;
  bottom: 1.6rem;
  right: 14.6rem;
  background: var(--white);
  border: 0;
  padding: 1.6rem;
  color: var(--light-purple-darkest);
  min-width:13rem;

  &:hover,
  &:focus {
    background: var(--white);
    cursor: pointer;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    position: absolute;
    bottom: 0rem;
    background: var(--white);
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    right: 16.6rem;
    bottom: 0;
    background: var(--light-purple-darkest);
    color: var(--white);
    &:hover,
    &:focus {
      background: var(--light-purple-darkest);
      color: var(--white);
    }
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    bottom: 1.6rem;
  }
`

const StyledRegisterNowButton = styled(StyledButton)`
  width: 100%;
  font-weight: 700;
  background-color: ${props => props.type === 'sleep-and-anxiety' ? 'var(--white);'
    : props.type === 'sleep-or-anxiety-not-both' ? 'var(--light-purple-darkest);'
      : props.type === 'offer-button' ? 'var(--white);'
        : 'var(--light-purple-mid-dark);'};
  ${props => props.type === 'sleep-or-anxiety-not-both' && 'border:1px solid #fff;'};
  ${props => props.type === 'offer-button' && 'border:1px solid var(--light-purple-darkest);'}
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  color: ${props => props.type === 'sleep-and-anxiety' ? 'var(--light-purple-darkest);'
    : props.type === 'sleep-or-anxiety-not-both' ? 'var(--white);'
      : props.type === 'offer-button' ? 'var(--light-purple-darkest);'
        : 'var(--white);'};

  @media screen and (min-width: ${breakpoints.sm}px) {
    float: left;
    border: 1px solid var(--light-purple-darkest);
    width: ${props => props.type === 'sleep-and-anxiety' ? '35%;'
      : props.type === 'sleep-or-anxiety-not-both' ? '35%;'
      : 'auto;'};
      margin: ${props => props.type === 'sleep-and-anxiety' ? '0 auto;'
        : props.type === 'sleep-or-anxiety-not-both' ? '0 auto;'
        : '1.6rem 1.6rem 1.6rem 0'};
      
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    width: ${props => props.type === 'sleep-and-anxiety' ? '35%;'
    : props.type === 'sleep-or-anxiety-not-both' ? '35%;'
      : 'auto;'};
    margin: ${props => props.type === 'sleep-and-anxiety' ? '0 auto;'
    : props.type === 'sleep-or-anxiety-not-both' ? '0 auto;'
      : '1.6rem 1.6rem 1.6rem 0'};
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    width: ${props => props.type === 'sleep-and-anxiety' ? '35%;'
    : props.type === 'sleep-or-anxiety-not-both' ? '35%;'
      : 'auto%;'};
    margin: ${props => props.type === 'sleep-and-anxiety' ? '0 auto;'
    : props.type === 'sleep-or-anxiety-not-both' ? '0 auto;'
      : '1.6rem 1.6rem 0 0'};
    ${props => props.placement === 'bottom' && 'margin-top: 1.6rem'};
  }
  span.strikethrough {
    ${strikethrough};
    color: var(--white);
    margin-right: 0.6rem;
    margin-left: 0.6rem;
  }
  &:hover {
    color: ${props => props.type ? 'var(--white)' : 'var(--white)'};
    background-color: 
  ${props => props.type === 'sleep-and-anxiety' ? 'var(--light-purple-darker);'
    : props.type === 'sleep-or-anxiety-not-both' ? 'var(--light-purple-darker);'
      : props.type === 'offer-button' ? 'var(--light-purple-darkest);'
        : 'var(--light-purple-darkest);'};
      ${props => props.type === 'offer-button' && 'border:1px solid #fff;'}
  }

  &.external {
    svg {
      width: 2rem;
    }
  }

  svg {
    width: 4.7rem;
    height: 1.6rem; 
    .stripe {
      fill: var(--white);
    }
  }
`

const StyledRegisterNowPayPalButton = styled(StyledButton)`
  width: 100%;
  font-weight: 700;
  background: var(--white);
  color: var(--light-purple-darkest);
  border: 0px solid var(--light-purple-darkest);
  padding: 1.5rem;

   @media screen and (min-width: ${breakpoints.sm}px) {
      width: auto;
      margin: 1.6rem 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    background: var(--grey-lightest);
  }
  &:visited {
    color: var(--light-purple-darkest);
    // background-color: var(--light-purple-darkest);

    svg {
      .pay {
        fill: #003087;
      }
      .pal {
        fill: #ffc439;
      }
    }
  }

  &:hover {
    color: var(--light-purple-dark);
    background-color: var(--white);
    @media screen and (min-width: ${breakpoints.md}px) {
      background: var(--grey-lightest);
    }
    svg {
      .pay {
        // fill: #fff;
      }
      .pal {
        // fill: #ffc439;
      }
    }
  }
  &:focus {
    color: var(--light-purple-darkest);
    background-color: var(--white);
    @media screen and (min-width: ${breakpoints.md}px) {
      background: var(--grey-lightest);
    }
  }


  svg {
    width: 6rem;
    height: 1.6rem;
    margin-left: 0.6rem;
    
  }
`

const StyledAddEventToCartButton = styled(StyledButton)`
  width: 100%;
  background: var(--light-purple-darkest);
  margin-top: 0.8rem;
  @media screen and (min-width: ${breakpoints.l}px) {
    width: 80%;
    margin-left: 0;
  }

  :hover {
    background: var(--light-purple-darker);
    color: var(--white);
  }
`

const StyledDepositButton = styled(StyledButton)`
  width: 100%;
  clear: left;
  margin-top: 0rem;
  background: none;
  color: var(--light-purple-darkest);
  white-space: normal;
  text-decoration:underline;
  display:inline-block;
  @media screen and (min-width: ${breakpoints.md}px) {
    width: 100%;
    margin-left: 0;
    text-align: left;
    padding-left: 0;
    display: inline;
  }

  :hover,
  :active,
  :focus {
    background: none;
    color: var(--light-purple-darker);
  }
  &.limited-space {
    display: flex;
    flex-direction: row;
    justify-content: unset;
    padding-top: 0;
    margin-top:1.6rem;
    padding-bottom: 0;
    background: var(--white);
    text-align: left;
    padding-left: 0;
    padding-right: 0;

    strong {
      background-color: var(--green-strong);
      color: var(--white);
      padding: 0.8rem; 
      margin-right: 0.8rem;
      text-align: center;
    }
  }
  
`

const StyledButtonViewPassword = styled.button`
    position: absolute;
    right: 0;
    top: 2.1rem;
    border: 0;
    background: #0000;
  `

const StyledAnchorLink = styled(AnchorLink)`
  ${AnchorLinkStyles}

  &.testimonial-anchor {
    background: transparent;
  }
`

const StyledNotificationOfferButton = styled(Link)`
  background: var(--pink-mid);
  padding: 0.4rem 0.8rem;
  ${semibold16};
  color: var(--white);
  border-bottom: none;
  z-index: 4;
  display: block;
  position: absolute;
  bottom: 13px;
  left: 0;
  font-weight: 700;
  &:visited {
    color: var(--white);
    border-bottom: none;
    background: var(--pink-mid);
  }
  &:hover {
    color: var(--white);
    border-bottom: none;
    background: var(--pink-light);
  }
`

const StyledWhatsappButton = styled.a`
  position: relative;
  background: #128733;
  border-radius: 1.6rem;
  border-bottom: 0;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 40%);
  position: fixed;
  bottom: 1.6rem;
  right: 1.6rem;
  padding: 0.8rem;
  width: 15.8rem;
  transition: opacity 1s ease-out;
  opacity: 1;
  right: ${props => props.isHomePage ? '-200px' : '1.6rem'};
  animation: slide 0.5s forwards;
  animation-delay: 2s;
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-left: 1.2rem;
  }
  &:hover {
    border: 0;
  }
  svg {
    fill: #fff;
    width: 2rem;
    height: 2rem;
  }
  span {
    left: 35px;
    top: 7px;
    color: var(--white);
    ${bold16}
    position: absolute;

    @media screen and (min-width: ${breakpoints.md}px) {
      left: 40px;
    }
  }

  @keyframes slide {
      100% { right: 1.6rem; }
  }
`

export {
  StyledButton,
  StyledButtonLink,
  StyledLinkButton,
  StyledCloseModalButton,
  StyledAddToCartButton,
  StyledAddEventToCartButton,
  StyledCheckoutButton,
  StyledClearCartButton,
  StyledRegisterNowButton,
  StyledRegisterNowPayPalButton,
  StyledDepositButton,
  StyledButtonViewPassword,
  StyledAnchorLink,
  StyledNotificationOfferButton,
  StyledButtonAsLink,
  StyledWhatsappButton
}
